
function stick(){

  
  
    var topHeight = $('#header').outerHeight() + 20; 
    $contentareapadding = parseInt($('.content-area').css('padding-bottom'),10);
      
    var botHeight = $('#footer').outerHeight() + $contentareapadding;

    $windowwrapper = $(window).height() - topHeight;
    $sidebarwrapper = $('.sidebar-wrapper').outerHeight();


   
      
      $(".sidebar").sticky({
        topSpacing: topHeight,
        bottomSpacing: botHeight
      });

    


}

function unstick() {
  $('.sidebar').unstick();
}


function detectSafari(){
  var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return is_safari;
}

function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
       // Edge (IE 12+) => return version number
       return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

function initialLoad(){
  
  (function($){

    $('.lazy').Lazy({
      // your configuration goes here
      scrollDirection: 'vertical',
      effect: 'fadeIn',
      effect: "fadeIn",
      effectTime: 500,
      threshold: 0,
      chainable: true,
      visibleOnly: true,
      onError: function(element) {
          console.log('error loading ' + element.data('src'));
      }
    });

  })(jQuery);
}

function sliderLoader(){

  (function($){


    $('.slick-slider').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: false,
          infinite: true,
          autoplay: auto,
          autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          swipe: false,
          fade: transition,
          cssEase: 'linear',
        });

      }
    });

  })(jQuery);

}


