(function($){

	/*
	* Mobile Menu
	*/
  	$(document).on('click tap','.mobile-menu-ctrl',function(){

	    if($('.mobile-menu-ctrl').hasClass('active')){
			$('.mobile-menu-ctrl').removeClass('active');
			$('body').removeClass('mobile-active').removeClass('overlay-open');
	    }else{
			$('.mobile-menu-ctrl').addClass('active');
			$('body').addClass('mobile-active').addClass('overlay-open');
	    }

	    return false;
 	});

  	//$('.mobile-menu-list .menu-item-has-children > a').append('<span class="sub-ctrl"><i class="fas fa-caret-right"></i></span>');
  	$('.mobile-menu-list').on('click tap','.sub-ctrl',function(){

	    if($(this).closest('li').hasClass('active')){
			$(this).closest('li').removeClass('active');
			$(this).next('.sub-menu').slideUp();
	    }else{
			$(this).closest('li').addClass('active');
			$(this).next('.sub-menu').slideDown();
	    }
	    return false;
  	});

	/*
	* Modal
	*/
	$(document).on('click tap','.btn-modal',function(){

    	$('#modalDefault').modal('show');

    	return false;
  	});
 
	/*
	* Scroll to Specific Div
	*/
  	$(document).on('click tap','.scroll-to-div',function(){
	    var divSelected = $(this).data('div');
	    $('html, body').animate({
	        scrollTop: $("#"+divSelected).offset().top - $('#header').outerHeight()
	    }, 2000);
	    return false;
  	});


	/*
	* Back to Top
	*/
	$(window).on('scroll',function () {
		if ($(this).scrollTop() > 100) {
			$('#back-top').addClass('active');
		} else {
			$('#back-top').removeClass('active');
		}

		if ($(this).scrollTop() > $('#header .top').outerHeight()) {
			$('#header').addClass('scrolled');
		}else{
			$('#header').removeClass('scrolled');
		}
	});
	$('#back-top').on('click tap',function () {
		$('#back-top').addClass('fired');
		$('body,html').animate({
		    scrollTop: 0
		}, 800, function() {  
		  $('#back-top').removeClass('fired');
		  $('#back-top').removeClass('active');
		});
		return false;
	});


	/*
	* Window Load
	*/
	$(window).on("load", function(){

    	/* Site Loader */
	    if($('.cssload-wrapper').length){
	       $('.cssload-wrapper').fadeOut();
	    }
	    $(window).resize();
	});

  	/*
	* Window Resize
	*/
	$(window).resize(function() {
    
 	 	/* Match Height JQuery */
    	jQuery.fn.matchHeight._update();

    	/* Sticky Sidebar */
    	if($('.sidebar').length){
  			$('.sidebar').sticky('update');

	      if (Modernizr.mq('(min-width: 992px)')) {
	        stick();
	      }else{
	        unstick();
	      }
	    }
 	});

 	$(window).on('orientationchange', function() {
    
 	 	/* Match Height JQuery */
    	jQuery.fn.matchHeight._update();

    	/* Sticky Sidebar */
    	if($('.sidebar').length){
			
			$('.sidebar').sticky('update');

	      	if (Modernizr.mq('(min-width: 992px)')) {
	        	stick();
	      	}else{
	        	unstick();
	      	}
	    }
 	});

 	$(document).on('change', '#country', function() {
 		var value = $(this).val();
 		$('.tuts').each(function(){
 			if( $(this).hasClass(value) ){
 				$(this).addClass('active');
 			} else {
 				$(this).removeClass('active');
 			}
 		});
	});
		$(document).on('click', '.gallery-thumbnail', function(){
			var GetPostId = $(this).data('getid');
			var GetKey = $(this).data('getkey');
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'fetch_gallery',
					get_id : GetPostId,
					get_key : GetKey

				},
				beforeSend: function(){	
					$('.loader-wrapper').show();
					$('.slider').hide();
					$('.slider-nav').hide();

				},

				success: function(response) {
					$('.slider').html(response.content);
					$('.loader-wrapper').hide();
					$('.slider').show();
					$('.slider-nav').show();
					setTimeout(function(){

						$('.slider > .slickholder').slick({

						  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  				  
		  				  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',

						  slidesToShow: 1,

						  slidesToScroll: 1,

						  arrows:true,

						  fade: true,

						  speed: 100,

						  autoplay: false,

						  asNavFor: '.slider > .slider-nav',
						  adaptiveHeight: true

						});

						$('.slider > .slider-nav').slick({

						  slidesToShow: 4,

						  slidesToScroll: 1,

						  asNavFor: '.slider > .slickholder',

						  autoplay: false,

						  dots: false,

						  arrows:false,

						  centerMode: false,

						  focusOnSelect: true,
						  adaptiveHeight: true

						});

					}, 500);

				}

			});

		});
		setTimeout(function(){

			$('.slider > .slickholder').slick({

			  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  	  
		  	  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',

			  slidesToShow: 1,

			  slidesToScroll: 1,

			  arrows:true,

			  fade: true,

			  speed: 100,

			  autoplay: false,

			  asNavFor: '.slider > .slider-nav',
						  adaptiveHeight: true

			});

			$('.slider > .slider-nav').slick({

			  slidesToShow: 4,

			  slidesToScroll: 1,

			  asNavFor: '.slider > .slickholder',

			  autoplay: false,

			  dots: false,

			  arrows:false,

			  centerMode: false,

			  focusOnSelect: true,
						  adaptiveHeight: true

			});

		}, 500);

		$(document).on('click', '.search-btn', function () {
			$('body, html').css({
				'overflow': 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function () {
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function () {
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function () {
				$('.search-form-template').removeClass('animate');
			}, 300);
		});

})(jQuery);