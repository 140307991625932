(function($){

	// Global Variables
	var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
	    $navHeight = $('header.header').outerHeight();
				   
 	$('.mailto-container .mailto-contain').css({
		'padding-top': $navHeight
	});
  	sliderLoader();
 	initialLoad();
	headerPushTop();
  	if($('.sidebar').length){
    	stick();
  	}

  	if($('.matchContent').length){
	  $('.matchContent').matchHeight({
	    byRow : false
	  });
	}

	if($('.lazy').length){
		$('.lazy').Lazy({ chainable: false });
	}
	
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
		} else {
			$('.back-to-top').fadeOut();
			
		}
		if ($(this).scrollTop() > 50) {
			
			$('html').addClass('scrollstick');
		} else {
			
			$('html').removeClass('scrollstick');
		}
	});
	$(document).on('click', 'a[href^="mailto:"].main', function (e) {
			e.preventDefault();

			$('html').css({
				'overflow': 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function () {
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function () {
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function () {
				$('.mailto-container').removeClass('show');
			}, 300);
		});

	//HOME BANNERS
 //  	if($('#home-banner .bg').length){

	// 	var heroImages = [];
	//     $('#home-banner .bg img').each(function(){
	//     	heroImages.push({'src' : $(this).attr('data-src')})
	//     });

	//      console.log(heroImages);
	// 	var slidesCount = heroImages.length;
          
	// 	$('#home-banner .bg').vegas({
	//         slides: heroImages,
	//         timer: false,
	//       	delay: 15000,
	//       	transition : ['fade'],
	//       	animation: '',
	//       	overlay: false
	//     });

	// 	$('#home-banner .controls').on('click','.prev',function(){ 
	// 		$('#home-banner .bg').vegas('previous');

	// 		return false;
	// 	});
	// 	$('#home-banner .controls').on('click','.next',function(){ 
	// 		$('#home-banner .bg').vegas('next');

	// 		return false;
	// 	});
	// }
    //  $('#homebanner').on('init', function(e, slick) {
    //     var $firstAnimatingElements = $('.hb-content-holder:first-child').find('[data-animation]');
    //     doAnimations($firstAnimatingElements);    
    // });
    // $('#hero-slider').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
    //           var $animatingElements = $('.hb-content-holder[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
    //           doAnimations($animatingElements);    
    // });
	// $('#homebanner').slick({
	// 	dots: false,
	// 	autoplay: false,
	// 	arrows: true,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	adaptiveHeight: true,
	// 	fade: true,
	//     speed: 1500,
	//     pauseOnHover: false,
	//     infinite: true,

	// 	prevArrow: '<button type="button" class="slick-prev slick-controller"><i class="fa fa-angle-left"></i></button>',
	// 	nextArrow: '<button type="button" class="slick-next slick-controller"><i class="fa fa-angle-right"></i></button>'
	// });

	function doAnimations(elements) {

	        var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
	        elements.each(function() {
	            var $this = $(this);
	            var $animationDelay = $this.data('delay');
	            var $animationType = 'animated ' + $this.data('animation');
	            $this.css({
	                'animation-delay': $animationDelay,
	                '-webkit-animation-delay': $animationDelay
	            });
	            $this.addClass($animationType).one(animationEndEvents, function() {
	                $this.removeClass($animationType);
	            });
	        });
	}
	function headerPushTop(){
	  var doc = document.documentElement;
	  var w = window;

	  var prevScroll = w.scrollY || doc.scrollTop;
	  var curScroll;
	  var direction = 0;
	  var prevDirection = 0;

	  var header = $('header#header');

	  var checkScroll = function() {

	    /*
	    ** Find the direction of scroll
	    ** 0 - initial, 1 - up, 2 - down
	    */

	    curScroll = w.scrollY || doc.scrollTop;
	    if (curScroll > prevScroll) { 
	      //scrolled up
	      direction = 2;
	    }
	    else if (curScroll < prevScroll) { 
	      //scrolled down
	      direction = 1;
	    }

	    if (direction !== prevDirection) {
	      toggleHeader(direction, curScroll);
	    }
	    
	    prevScroll = curScroll;
	  };

	  var toggleHeader = function(direction, curScroll) {
	    if (direction === 2 && curScroll > 52) {
	      header.addClass('push-top');
	      prevDirection = direction;
	    }
	    else if (direction === 1) {
	      header.removeClass('push-top');
	      prevDirection = direction;
	    }
	  };
	  
	  window.addEventListener('scroll', checkScroll);
	}

})(jQuery);

